import { Stack, Typography } from "@mui/material";

import { getEnvironment } from "../../utils/environment";

const getRibbonColorByEnvironment = () => {
  switch (getEnvironment()) {
    case "DEV":
      return "primary.main";
    case "ACCEPTANCE":
      return "secondary.main";
    case "PROD":
      return "success.main";
    default:
      return "warning.main";
  }
};

const OverlayRibbon = () => {
  return (
    <Stack
      sx={{
        opacity: "75%",
        position: "fixed",
        bottom: "-200px",
        right: "-25px",
        width: "150px",
        height: "500px",
        zIndex: 9999,
        pointerEvents: "none",
        transform: "rotate(45deg)",
        backgroundColor: getRibbonColorByEnvironment(),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        boxShadow: 3,
      }}
    >
      <Typography
        sx={{
          transform: "rotate(-90deg)",
          marginRight: "48px",
          color: "white",
        }}
        variant="subtitle1"
      >
        {getEnvironment()}
      </Typography>
    </Stack>
  );
};

export default OverlayRibbon;
