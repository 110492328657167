import { Circle, Line } from "@react-three/drei";
import React from "react";
import type { BufferGeometry, Euler, Vector3 } from "three";
import { DoubleSide, MeshBasicMaterial } from "three";

interface CrosshairProps {
  position: Vector3;
  rotation: Euler;
  dotColor: string;
  lineColor: string;
  lineWidth: number;
  radius: number;
  segments: number;
  geometryX: BufferGeometry;
  geometryY: BufferGeometry;
}

export const Crosshair = ({
  position,
  rotation,
  dotColor,
  lineColor,
  lineWidth,
  radius,
  segments,
  geometryX,
  geometryY,
}: CrosshairProps) => {
  return (
    <>
      <Circle
        args={[radius, segments, segments]}
        rotation={rotation}
        position={position}
        renderOrder={-99999999999}
        material={
          new MeshBasicMaterial({
            color: dotColor,
            side: DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: -99999999999,
            polygonOffsetUnits: -99999999999,
          })
        }
      />
      <Line
        points={Array.from(geometryX.getAttribute("position").array)}
        position={position}
        rotation={rotation}
        renderOrder={999}
        color={lineColor}
        polygonOffset
        polygonOffsetFactor={-999}
        polygonOffsetUnits={-999}
        lineWidth={lineWidth}
      />
      <Line
        points={Array.from(geometryY.getAttribute("position").array)}
        position={position}
        rotation={rotation}
        renderOrder={999}
        color={lineColor}
        polygonOffset
        polygonOffsetFactor={-999}
        polygonOffsetUnits={-999}
        lineWidth={lineWidth}
      />
    </>
  );
};
