import type { ViewerObject } from "@promaton/scan-viewer";
import { getCenterOfObjectById, useObjects } from "@promaton/scan-viewer";
import { useEffect } from "react";
import { Vector3 } from "three";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";
import { createCustomArrow, generate3DObjectSTLURL } from "../utils/3d";
import { generateRandomGuideColorWithSeed } from "../utils/color";

const INSERTION_DIRECTION_OFFSET = 25;
const INSERTION_DIRECTION_ARROW_LENGTH = 15;
const INSERTION_DIRECTION_ARROW_WIDTH = 0.5;
const SCAN_REGEX = /lower|upper|mandible|maxilla/i;

export const useLoadInsertionDirection = (taskId?: string) => {
  const guideParams = useAppState((s) => s.guideParams);
  const objects = useObjects((s) => s.objects);
  const setObject = useObjects((s) => s.setObject);
  const currentStep = useAppState((s) => s.currentStep);

  useEffect(() => {
    const loadInsertionDirection = async () => {
      if (!guideParams || currentStep !== Step.REVIEW_CONTACT_POINTS) return;

      const scanName = Object.keys(objects).find((key) => SCAN_REGEX.exec(key));

      const scanCenter = scanName && getCenterOfObjectById(scanName);
      const origin =
        scanCenter &&
        new Vector3(
          scanCenter.center.x,
          scanCenter.center.y + INSERTION_DIRECTION_OFFSET,
          scanCenter.center.z
        );

      const insertionDirection = new Vector3(
        guideParams.insert_direction.x,
        guideParams.insert_direction.z,
        guideParams.insert_direction.y
      );

      const arrowHelper =
        origin &&
        createCustomArrow(
          insertionDirection,
          1,
          origin,
          INSERTION_DIRECTION_ARROW_WIDTH,
          INSERTION_DIRECTION_ARROW_LENGTH
        );

      const arrowStlUrl =
        arrowHelper && (await generate3DObjectSTLURL(arrowHelper));
      if (arrowStlUrl) {
        const arrowObject: ViewerObject | undefined = {
          url: arrowStlUrl,
          group: "Arrows",
          objectType: "stl",
          clipToPlanes: true,
          flatShading: true,
          roughness: 0.8,
          metalness: 0.2,
          color: generateRandomGuideColorWithSeed(taskId),
        };

        arrowObject && setObject("insertionDirection", arrowObject);
      }
    };

    if (guideParams && currentStep === Step.REVIEW_CONTACT_POINTS) {
      loadInsertionDirection();
    }
  }, [guideParams, currentStep]);
};
