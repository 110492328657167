import { Line } from "@react-three/drei";
import React from "react";
import type { BufferGeometry, Vector3 } from "three";

interface CircumferenceLineProps {
  geometry: BufferGeometry;
  position: Vector3;
  color: string;
  renderOrder: number;
  lineWidth: number;
}

export const CircumferenceLine = ({
  geometry,
  position,
  color,
  renderOrder,
  lineWidth,
}: CircumferenceLineProps) => {
  return (
    <Line
      points={Array.from(geometry.getAttribute("position").array)}
      position={position}
      renderOrder={renderOrder}
      color={color}
      polygonOffset
      polygonOffsetFactor={-renderOrder}
      polygonOffsetUnits={-renderOrder}
      lineWidth={lineWidth}
    />
  );
};
