import { Cylinder, useCursor } from "@react-three/drei";
import type { ThreeEvent } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import type { Mesh } from "three";
import { Euler, Vector3 } from "three";

import { Step } from "../../enums/step";
import { getPositionCorrectionForInspectionWindow } from "../../utils/3d";
import {
  generateCircumferenceGeometry,
  generateCrosshairGeometry,
  generateVerticalEdgesGeometry,
} from "../../utils/geometry";
import { CircumferenceLine } from "../Geometry/CircumferenceLine";
import { Crosshair } from "../Geometry/Crosshair";

const INSPECTION_WINDOW_OPACITY = 0.5;
const INSPECTION_WINDOW_OUTLINE_COLOR = "black";
const INSPECTION_WINDOW_OUTLINE_COLOR_HOVERED = "blue";
const INSPECTION_WINDOW_OUTLINE_WIDTH = 1;
const CROSSHAIR_DOT_COLOR = "lime";
const CROSSHAIR_LINE_COLOR = "black";
const CROSSHAIR_RADIUS = 0.5;
const CYLINDER_SEGMENTS = 16;
const RENDER_ORDER_FRONT = 99999999999;

interface InspectionWindowProps {
  point: InspectionWindow;
  index: number;
  currentStep: number;
  inspectionWindowRef: React.MutableRefObject<Mesh | null>;
  onPointerDown: (event: ThreeEvent<PointerEvent>) => void;
}

export const InspectionWindow = ({
  point,
  index,
  currentStep,
  inspectionWindowRef,
  onPointerDown,
}: InspectionWindowProps) => {
  const cylinderRef = useRef<Mesh>(null);
  inspectionWindowRef.current = cylinderRef.current;

  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  const position = getPositionCorrectionForInspectionWindow(point);
  const bottomElementsPosition = new Vector3(
    point.position_x,
    point.position_z,
    -point.position_y
  );
  const bottomElementsRotation = new Euler(-Math.PI / 2, 0, 0);

  const cylinderArgs: [number, number, number, number, number] = [
    point.diameter / 2,
    point.diameter / 2,
    point.height,
    CYLINDER_SEGMENTS,
    CYLINDER_SEGMENTS,
  ];

  const topCircumferenceGeometry = generateCircumferenceGeometry(
    point.diameter / 2,
    CYLINDER_SEGMENTS,
    point.height / 2
  );

  const bottomCircumferenceGeometry = generateCircumferenceGeometry(
    point.diameter / 2,
    CYLINDER_SEGMENTS,
    -point.height / 2
  );

  const verticalEdgesGeometryA = generateVerticalEdgesGeometry(
    0,
    point.diameter,
    point.height
  );

  const verticalEdgesGeometryB = generateVerticalEdgesGeometry(
    Math.PI,
    point.diameter,
    point.height
  );

  const crosshairGeometryX = generateCrosshairGeometry("X", point.diameter);
  const crosshairGeometryY = generateCrosshairGeometry("Y", point.diameter);

  return (
    <group
      onPointerDown={onPointerDown}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      name={`inspection_window_${index}`}
    >
      <Cylinder
        key={`cp-${index}-${point.position_x + point.position_y + point.position_z}`}
        args={cylinderArgs}
        renderOrder={RENDER_ORDER_FRONT}
        position={position}
        ref={cylinderRef}
      >
        <meshBasicMaterial
          color={
            hovered
              ? INSPECTION_WINDOW_OUTLINE_COLOR_HOVERED
              : INSPECTION_WINDOW_OUTLINE_COLOR
          }
          transparent
          opacity={
            currentStep === Step.REVIEW_INSPECTION_WINDOWS
              ? INSPECTION_WINDOW_OPACITY
              : 0
          }
        />
      </Cylinder>

      <CircumferenceLine
        geometry={topCircumferenceGeometry}
        position={position}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        renderOrder={RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <CircumferenceLine
        geometry={bottomCircumferenceGeometry}
        position={position}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        renderOrder={RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <CircumferenceLine
        geometry={verticalEdgesGeometryA}
        position={position}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        renderOrder={RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <CircumferenceLine
        geometry={verticalEdgesGeometryB}
        position={position}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        renderOrder={RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <Crosshair
        position={bottomElementsPosition}
        rotation={bottomElementsRotation}
        dotColor={CROSSHAIR_DOT_COLOR}
        lineColor={CROSSHAIR_LINE_COLOR}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
        radius={CROSSHAIR_RADIUS}
        segments={CYLINDER_SEGMENTS}
        geometryX={crosshairGeometryX}
        geometryY={crosshairGeometryY}
      />
    </group>
  );
};
