import {
  useLoadingState,
  useObjects,
  useViewId,
  useViews,
} from "@promaton/scan-viewer";
import { useEffect } from "react";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";

export const useRecenterByStep = () => {
  const jawType = useAppState((s) => s.jawType);

  const currentStep = useAppState((s) => s.currentStep);
  const firstLoadComplete = useLoadingState((s) => s.firstLoadComplete);
  const objects = useObjects((s) => s.objects);
  const viewID = useViewId();
  const recenter = useViews((s) => s.views[viewID]?.recenter);

  useEffect(() => {
    if (
      !firstLoadComplete ||
      !jawType ||
      currentStep === Step.SET_GUIDE_PARAMETERS
    )
      return;

    if (
      currentStep === Step.REVIEW_VIEW_DIRECTION ||
      currentStep === Step.APPROVE_GUIDE
    ) {
      recenter?.([0, 0, 1]);
      return;
    }

    recenter?.([0, 1, 0]);
  }, [currentStep, recenter, firstLoadComplete, objects, jawType]);
};
