import {
  useObjects,
  useViewerContext,
  waitForObjectLoad,
} from "@promaton/scan-viewer";
import React, { useCallback, useEffect, useRef } from "react";
import { useAsync } from "react-use";
import type { AsyncState } from "react-use/lib/useAsyncFn";
import { type Mesh } from "three";

import { IntersectionTool } from "../../../../../packages/mesh-intersection-utils/src";
import { VisualisationMode } from "../../../../../packages/mesh-intersection-utils/src/static/enums";
import { Step } from "../../enums/step";
import { useRecenterByStep } from "../../hooks/useRecenterByStep";
import { useAppState } from "../../store/AppState";
import { resetHeatmap } from "../../utils/3d";
import { requestGuideParamsFromAPI } from "../../utils/api";
import { captureAndReportAxiosError } from "../../utils/error";
import { InspectionWindow } from "./InspectionWindow";

interface InspectionWindowsProps {
  taskId: string;
}

export const InspectionWindows = ({ taskId }: InspectionWindowsProps) => {
  useRecenterByStep();

  const selectedWindowRef = useRef<Mesh>(null);

  const guideParams = useAppState((s) => s.guideParams);
  const setGuideParams = useAppState((s) => s.setGuideParams);
  const currentStep = useAppState((s) => s.currentStep);
  const setIsViewerLoading = useAppState((s) => s.setViewerIsLoading);
  const setIsNextButtonEnabled = useAppState((s) => s.setIsNextButtonEnabled);
  const setCurrentLoadingProgress = useAppState(
    (s) => s.setCurrentLoadingProgress
  );
  const errors = useAppState((s) => s.errors);
  const setErrors = useAppState((s) => s.setErrors);
  const apiKey = useAppState((s) => s.apiKey);

  const getMeshByObjectId = useViewerContext((s) => s.getMeshByObjectId);
  const objects = useObjects((s) => s.objects);
  const isInspectionWindowsEnabled = useAppState(
    (s) => s.isInspectionWindowsEnabled
  );

  const guideMesh: AsyncState<Mesh | null> = useAsync(async () => {
    const guideId = Object.entries(objects).find(([id, _]) =>
      id.match(/guide/i)
    )?.[0];

    let mesh: Mesh | null = null;
    if (guideId) {
      await waitForObjectLoad(guideId);
      mesh = getMeshByObjectId(guideId);
    }

    return mesh;
  }, [objects, getMeshByObjectId, currentStep]);

  const onClickInspectionWindow = useCallback(() => {
    const tool = new IntersectionTool(
      guideMesh.value,
      selectedWindowRef.current,
      {
        maxDistance: 1,
        heatThreshold: 0.1,
        visualisationMode: VisualisationMode.Heatmap,
      }
    );

    tool.updateOnChange();
  }, [guideMesh, selectedWindowRef]);

  useEffect(() => {
    if (currentStep !== Step.REVIEW_INSPECTION_WINDOWS && guideMesh.value) {
      resetHeatmap(guideMesh.value, guideMesh.value);
    }

    if (
      currentStep === Step.REVIEW_INSPECTION_WINDOWS &&
      guideParams &&
      guideParams.contact_points_params.length > 0
    ) {
      setIsNextButtonEnabled(true);
    }
  }, [guideParams, currentStep]);

  useAsync(async () => {
    try {
      if (
        !taskId ||
        guideParams ||
        currentStep !== Step.REVIEW_INSPECTION_WINDOWS
      )
        return;

      setIsViewerLoading(true);
      setCurrentLoadingProgress(() => 0);

      const data = await requestGuideParamsFromAPI(
        taskId,
        isInspectionWindowsEnabled,
        apiKey,
        setCurrentLoadingProgress
      );

      setGuideParams(data);
      setIsNextButtonEnabled(true);
      setIsViewerLoading(false);
      return data;
    } catch (err) {
      await captureAndReportAxiosError(
        err,
        errors,
        setErrors,
        "Error generating inspection windows"
      );
    }
  }, [taskId, currentStep, apiKey]);

  if (!guideParams || currentStep !== Step.REVIEW_INSPECTION_WINDOWS)
    return null;

  return (
    <group name="inspection_windows">
      {guideParams.inspection_windows_params.map((point, index) => (
        <InspectionWindow
          key={point.id}
          point={point}
          index={index}
          currentStep={currentStep}
          inspectionWindowRef={selectedWindowRef}
          onPointerDown={onClickInspectionWindow}
        />
      ))}
    </group>
  );
};
