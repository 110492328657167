import { guide } from "@promaton/api-client";
import type { ViewerObject } from "@promaton/scan-viewer";
import { useObjects } from "@promaton/scan-viewer";
import { useCallback, useState } from "react";
import { useAsync } from "react-use";
import { Matrix3, Matrix4 } from "three";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";
import { getAPIOptions } from "../utils/api";
import { captureAndReportAxiosError } from "../utils/error";
import { inferJawTypeFromFDI } from "../utils/fdi";
import { unpackZip } from "../utils/file";
import { extractSleeveAndFDIInformation } from "../utils/sleeve";

export const useLoadScan = (taskId?: string) => {
  const setObject = useObjects((s) => s.setObject);
  const setIsScanLoaded = useAppState((s) => s.setIsScanLoaded);
  const currentStep = useAppState((s) => s.currentStep);
  const setIsViewerLoading = useAppState((s) => s.setViewerIsLoading);
  const setIsNextButtonEnabled = useAppState((s) => s.setIsNextButtonEnabled);
  const setSleeveParameters = useAppState((s) => s.setSleeveParameters);
  const setImplantFDI = useAppState((s) => s.setImplantFDI);
  const setJawType = useAppState((s) => s.setJawType);

  const setCurrentLoadingProgress = useAppState(
    (s) => s.setCurrentLoadingProgress
  );
  const errors = useAppState((s) => s.errors);
  const setErrors = useAppState((s) => s.setErrors);
  const apiKey = useAppState((s) => s.apiKey);
  const setAffineMatrix = useAppState((s) => s.setAffineMatrix);

  const [isRetrievingScan, setIsRetrievingScan] = useState(false);

  const loadImplant = useCallback(
    (results: File[], transform: Matrix4) => {
      const implantStl = results.find((result) => /implant/i.exec(result.name));

      const implantObject: ViewerObject | undefined = implantStl && {
        url: URL.createObjectURL(implantStl),
        group: "Implant",
        objectType: "stl",
        clipToPlanes: true,
        color: "slategrey",
        metalness: 0.5,
        roughness: 0.2,
        transform,
      };

      implantObject && setObject(implantStl.name, implantObject);
    },
    [setObject]
  );

  const loadSleeve = useCallback(
    (results: File[], transform: Matrix4) => {
      const sleeveStl = results.find((result) => /sleeve/i.exec(result.name));

      const sleeveObject: ViewerObject | undefined = sleeveStl && {
        url: URL.createObjectURL(sleeveStl),
        group: "Sleeves",
        objectType: "stl",
        clipToPlanes: true,
        color: "slategrey",
        transform,
      };

      sleeveObject && setObject("sleeve", sleeveObject);
    },
    [setObject]
  );

  const loadScan = useCallback(
    (results: File[], transform: Matrix4) => {
      const scanStl = results.find(
        (result) => /upper/i.exec(result.name) || /lower/i.exec(result.name)
      );

      if (!scanStl) return;

      const scanObject: ViewerObject = scanStl && {
        url: URL.createObjectURL(scanStl),
        group: "Scans",
        objectType: "stl",
        renderOrder: 1,
        clipToPlanes: true,
        transform,
      };

      const id = `${scanStl.name}-${Date.now()}`;

      setObject(id, scanObject);
      setIsScanLoaded(true);

      return id;
    },
    [setObject]
  );

  const loadGuideParams = useCallback(
    (guideDesignJson: GuideDesignJSONResults) => {
      const sleeveAndFDIInformation = extractSleeveAndFDIInformation(
        guideDesignJson.DrillGuideDesign
      );

      setSleeveParameters(sleeveAndFDIInformation.sleeveParameters);
      setImplantFDI(sleeveAndFDIInformation.implant_fdi);
    },
    [setSleeveParameters, setImplantFDI]
  );

  const compressedFolder = useAsync(async () => {
    if (
      !apiKey ||
      isRetrievingScan ||
      !taskId ||
      currentStep !== Step.REVIEW_VIEW_DIRECTION
    )
      return;

    try {
      setIsRetrievingScan(true);
      setIsViewerLoading(true);

      const options = getAPIOptions(setCurrentLoadingProgress, apiKey, {
        responseType: "arraybuffer",
      });
      const response =
        await guide.getCompressedFolder.getCompressedFolderGetCompressedFolderTaskIdGet(
          taskId,
          options
        );

      const arrayBuffer: ArrayBuffer = response?.data as ArrayBuffer;
      const file = new Blob([new Uint8Array(arrayBuffer)], {
        type: "application/octet-stream",
      });

      const results = await unpackZip(file);

      const guideDesignJsonFile = results.find((result) =>
        /GuideDesign.json/i.exec(result.name)
      );

      const ossResultsJsonFile = results.find((result) =>
        /result.json/i.exec(result.name)
      );

      let affineMatrix = new Matrix4();

      if (ossResultsJsonFile) {
        const ossJson: OSSResults = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (event) {
            const arrayBuffer = event?.target?.result as ArrayBuffer;
            const text = new TextDecoder().decode(arrayBuffer);
            text && resolve(JSON.parse(text));
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(ossResultsJsonFile);
        });

        affineMatrix = new Matrix4().setFromMatrix3(
          new Matrix3().fromArray(ossJson.orientation.flat())
        );

        setAffineMatrix(affineMatrix.invert());
      }

      if (guideDesignJsonFile) {
        const guideDesignJson: GuideDesignJSONResults = await new Promise(
          (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
              const arrayBuffer = event?.target?.result as ArrayBuffer;
              const text = new TextDecoder().decode(arrayBuffer);
              text && resolve(JSON.parse(text));
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(guideDesignJsonFile);
          }
        );

        loadGuideParams(guideDesignJson);
        const fdi =
          guideDesignJson.DrillGuideDesign.SleeveMounts.SleeveMount[0]
            .ToothPosition;

        loadScan(results, affineMatrix);
        const jawType = inferJawTypeFromFDI(fdi);

        setJawType(jawType);
        loadImplant(results, affineMatrix);
        loadSleeve(results, affineMatrix);
      }

      setCurrentLoadingProgress(() => 0);
      setIsNextButtonEnabled(true);
      setIsViewerLoading(false);

      return results;
    } catch (err: unknown) {
      await captureAndReportAxiosError(
        err,
        errors,
        setErrors,
        "Error loading scan"
      );
    }
  }, [taskId, currentStep, isRetrievingScan, apiKey]);

  return compressedFolder;
};
